import request from '@/request';

// 获取课节详情
export function GetCourseLessonDetail (data) {
    return request({
        url: '/course/lesson/detail?courseLessonId='+data,
        method: 'get',
    });
}

// pc-视频题目获取
export function GetCourseLessonQuestion (data) {
    return request({
        url: '/course/lesson/question?courseLessonId='+data.courseLessonId+'&difficultyId='+data.difficultyId+'&fromTypeId='+data.fromTypeId+'&knowledgePointId='+data.knowledgePointId,
        method: 'get',
    });
}

// 提交课程学习记录 
export function CourseLessonStudy (data) {
    return request({
        url: '/course/lesson/study',
        method: 'post',
        data
    });
}