import request from '@/request';

// 错题本-获取模块统计V2
export function GetWrongQuestionCount (data) {
    return request({
        url: '/v2/user/wrong/question/count',
        method: 'get',
    });
}

// 错题本-获取模块下的题目
export function GetWrongQuestion (data) {
    return request({
        url: '/user/wrong/questions?knowledgePointId='+ data,
        method: 'get',
    });
}

// 错题本-题目重新作答提交
export function WrongQuestionAnswer (data) {
    return request({
        url: '/user/wrong/question/answer',
        method: 'post',
        data
    });
}

// 把题目移出错题本
export function WrongQuestionRemove (data) {
    return request({
        url: '/user/wrong/question/remove',
        method: 'post',
        data
    });
}

// 题目收藏-获取模块统计V2
export function GetCollectQuestionCount (data) {
    return request({
        url: '/v2/user/collect/question/count',
        method: 'get',
    });
}

// 题目收藏-获取模块下的题目
export function GetCollectQuestion (data) {
    return request({
        url: '/user/collect/questions?knowledgePointId='+ data,
        method: 'get',
    });
}

// 题目收藏、取消收藏
export function CollectQuestionPost (data) {
    return request({
        url: '/user/collect/question/post',
        method: 'post',
        data
    });
}

// 题目收藏-获取模块下的题目
export function GetUserPaperList (data) {
    return request({
        url: '/user/paper/list?page='+ data,
        method: 'get',
    });
}