<template>
    <transition name="ivu-anim-move-up">
    <div class="Topic" v-if="isshow">
        <div class="materials" v-if="SubjectList.datumText != ''">
            <div class="materials_top">
                <span>材料</span>
                <Button @click="openMaterials(SubjectList.datumText)">材料浮窗</Button>
            </div>
            <div class="materials_details" v-html="SubjectList.datumText" @click="imageEnlargement"></div>
        </div>
        <div class="stem">
            <span>{{questionIndex}}、</span>
            <Tag color="green" v-if="SubjectList.typeName == '判断题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <Tag color="magenta" v-if="SubjectList.typeName == '单选题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <Tag color="blue" v-if="SubjectList.typeName == '多选题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <span v-html="SubjectList.stem" class="question_stem" @click="imageEnlargement"></span>
        </div>
        <!-- 多选题 -->
        <div class="optionList" v-if="SubjectList.typeName == '多选题'">
            <!-- 未提交 -->
            <div class="option_item" v-for="(item,index) in SubjectList.optionList" :key="item.optId" @click="ClickMultipleoption(item,index)" v-if="!isSubmitAnswer">
                <div class="option_item_code" :class="{option_item_checked:item.isShow == true}">{{item.code}}</div>
                <p>{{item.con}}</p>
            </div>
            <!-- 已提交 -->
            <div class="option_item" v-for="(item,index) in SubjectList.optionList" :key="item.optId" v-if="isSubmitAnswer">
                <div class="option_item_code" :class="{option_item_correct:Arrcode.indexOf(item.code) != -1}" v-if="item.isAnswer">{{item.code}}</div>
                <div class="option_item_code" :class="{option_item_error:Arrcode.indexOf(item.code) != -1}" v-else>{{item.code}}</div>
                <p>{{item.con}}</p>
            </div>
        </div>
        <!-- 单选以及判断题 -->
        <div class="optionList" v-else>
            <div class="option_item" v-for="(item,index) in SubjectList.optionList" :key="item.optId" @click="Clickoption(item,index)">
                <div class="option_item_code" :class="{option_item_correct:optionindex == index}" v-if="item.isAnswer">{{item.code}}</div>
                <div class="option_item_code" :class="{option_item_error:optionindex == index}" v-else>{{item.code}}</div>
                <p>{{item.con}}</p>
            </div>
        </div>
        <div class="isCollect" v-if="SubjectList.isCollect"><Button type="primary" v-if="SubjectList.typeName == '多选题'" @click="SubmitAnswer">提交答案</Button><img src="@/assets/isCollect_true.png" alt="" @click="ClickCollect()"></div>
        <div class="isCollect" v-if="!SubjectList.isCollect"><Button type="primary" v-if="SubjectList.typeName == '多选题'" @click="SubmitAnswer">提交答案</Button><img src="@/assets/isCollect_false.png" alt="" @click="ClickCollect()"></div>
        <transition name="ivu-anim-slide-up">
            <div class="analysis" v-if="isanalysis">
                <div class="analysis_left">
                    <p>【答案解析】 正确答案：{{SubjectList.rightAnswerStr}} <span>您选的答案：{{code}}</span></p>
                    <div v-html="SubjectList.analysis" class="analysis_details" @click="imageEnlargement"></div>
                    <p>【考点模块】 {{SubjectList.knowledgeDesc}}</p>
                    <p>该知识点的全站正确率：{{SubjectList.knowledgeRightRate}}%</p>
                </div>
            </div>
        </transition>
        <div class="item_line"></div>
        <!-- 放大图片弹窗 -->
        <Modal v-model="Imgmodal" fullscreen footer-hide :closable="false">
            <div class="BIGimg" @click="Imgmodal = false"><img :src="BIGimg" alt=""></div>
        </Modal>
        
        <!-- 材料浮窗 -->
        <Modal v-model="MaterialsModal" draggable scrollable footer-hide :closable="false" width='485' title="材料">
            <div class="MaterialsModal" v-html="materialsData" @click="imageEnlargement"></div>
            <div class="Materialsclosable"><Button @click="MaterialsModal = false">关闭弹窗</Button></div>
        </Modal>
    </div>
    </transition>
</template>

<script>
import { CollectQuestionPost } from "@/api/Review"
import { QuestionAnswer} from "@/api/QuestionPapers"
export default {
    name: 'demo',
    props: {
        SubjectList: Object,
        questionIndex: Number
    },
    components: {},
    data () {
       return {
            // 放大图片
            Imgmodal: false,
            BIGimg: '',
            // 材料浮窗
            MaterialsModal: false,
            materialsData: '',
            optionindex: 999999,
            // 是否显示答案解析
            isanalysis: false,
            // 选择的答案
            code: "",
            // 是否能选择
            isselected: true,
            // 是否正确选项
            isAnswer: false,
            // 点击多选题
            isshow: true,
            // 多选题选中的答案数组
            Arrcode:[],
            ArroptId:[],
            // 多选题是否已提交
            isSubmitAnswer: false
        }
    },
    created () {
    },
    mounted () {
    },
    methods: {
        // 放大图片
        imageEnlargement(e){
            if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
                this.BIGimg = e.target.currentSrc
                this.Imgmodal = true
   	        } else {
   	        	console.log("点击内容不为img")
   	        }
        },

        // 打开材料浮窗
        openMaterials(e){
            this.materialsData = e
            this.MaterialsModal = true
        },

        // 取消收藏
        ClickCollect(){
            let CollectData = {
                questionId: this.SubjectList.questionId,
                userQuestionId: this.SubjectList.userQuestionId
            }
            CollectQuestionPost(CollectData).then((res) =>{
                if(res.ret == 1){
                    this.SubjectList.isCollect = !this.SubjectList.isCollect
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 点击多选选项
        ClickMultipleoption(item,index){
            if(!item.isShow) {   // 判断是否有isShow属性
				this.$set(item,'isShow',false)  // 没有则为当前对象设置该属性为false
				item.isShow = !item.isShow
			}else {
				item.isShow = !item.isShow
			}
            if (this.Arrcode.indexOf(item.code) === -1) {
                this.Arrcode.push(item.code)
                this.ArroptId.push(item.optId)
            } else {
                this.Arrcode.splice(this.Arrcode.indexOf(item.code), 1)
                this.ArroptId.splice(this.ArroptId.indexOf(item.optId), 1)
            }
            // 排序
            this.Arrcode.sort()
            this.ArroptId.sort()
            this.code = this.Arrcode.join("")
        },

        // 多选题提交答案
        SubmitAnswer(){
            if(this.code == ""){
                this.$Message.warning('该题目还未选择答案喔！');
                return
            }
            // 是否是正确答案
            if(this.SubjectList.rightAnswerStr == this.code){
                this.isAnswer = true
            }
            this.isanalysis =true
            this.isSubmitAnswer = true
            let AnswerData = {
                userQuestionId : this.SubjectList.userQuestionId,
                answer: this.ArroptId.join(","),
                questionUseTime: 1,
                paperUseTime: 1,
                isClassRoomPractice: 1
            }
            QuestionAnswer(AnswerData).then((res=>{
                if(res.ret == 1){
                    
                }else{
                    console.log(res.msg)
                }
            }))
        },

        // 点击选项
        Clickoption(data,index){
            // 第一次才能选择
            if(this.isselected){
                this.optionindex = index
                this.isanalysis =true
                this.code = data.code
                this.isselected = false
                // 是否是正确答案
                this.isAnswer = data.isAnswer
                let AnswerData = {
                    userQuestionId : this.SubjectList.userQuestionId,
                    answer: data.optId,
                    questionUseTime: 1,
                    paperUseTime: 1,
                    isClassRoomPractice: 1
                }
                QuestionAnswer(AnswerData).then((res=>{
                    if(res.ret == 1){

                    }else{
                        console.log(res.msg)
                    }
                }))
            }
        },
    },
}
</script> 

<style lang='less' scoped>
    .Topic{
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        .materials{
            background: #F5F5F5;
            border: 1px solid #D6D6D6;
            border-radius: 16px 8px 8px 8px;
            margin-bottom: 30px;
            .materials_top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px 30px 28px;
                font-size: 20px;
                font-weight: bold;
                color: #333333;
            }
            .materials_details{
                padding: 20px;
                color: #666666;
                background: #FFFFFF;
                border-top: 1px solid #D6D6D6;
                border-radius: 16px 8px 8px 8px;
                /deep/img{
                    display: block;
                    margin: 10px auto 0;
                    max-width: 1100px;
                }
            }
        }
        .stem{
            .question_stem{
                /deep/img{
                    display: block;
                    margin: 10px auto 0;
                    max-width: 1100px;
                }
            }
        }
        .optionList{
            margin-top: 30px;
            padding: 0 20px;
            .option_item{
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
                .option_item_code{
                    width: 32px;
                    height: 32px;
                    border: 1px solid #AAAAAA;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 32px;
                    margin: 0 10px;
                }
                .option_item_correct{
                    background-color: #2FC732;
                    color: #FFFFFF;
                    border: 1px solid #2FC732;
                }
                .option_item_error{
                    background-color: #FB7878;
                    color: #FFFFFF;
                    border: 1px solid #FB7878;
                }
                .option_item_checked{
                    background-color: #F29C05;
                    color: #FFFFFF;
                    border: 1px solid #F29C05;
                }
            }
            .option_item:hover{
                background: #F5F5F5;
                border-radius: 4px;
            }
            .option_item:last-child{
                margin-bottom: 0;
            }
        }
        .isCollect{
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img{
                cursor: pointer;
                margin-left: 30px;
            }
        }
        .analysis{
            background: #F5F5F5;
            border-radius: 8px;
            padding: 20px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .analysis_left{
                span{
                    margin-left: 20px;
                }
                .analysis_details{
                    margin-bottom: 30px;
                    /deep/img{
                        display: block;
                        margin: 10px auto 0;
                        max-width: 1100px;
                    }
                }
            }
            .analysis_right{
                text-align: right;
                p{
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 12px;
                }
            }
        }
        .item_line{
            border: 1px solid #F5F5F5;
            margin: 20px 0 30px;
        }
    }
    // 放大图片弹窗
    .BIGimg{
        width: 100%;
        height: 100%;
        text-align: center;
        img{
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 1900px;
            height: auto;
        }
    }
    // 材料弹窗
    .MaterialsModal{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding: 4px;
        margin-bottom: 37px;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        /deep/img{
            display: block;
            margin: 10px auto 0;
        }
    }
    .MaterialsModal::-webkit-scrollbar {
      width: 5px;
      height: 15px;
    }
    .MaterialsModal::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c1c1c1;
    }
    .MaterialsModal::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #ededed;
      border-radius: 10px;
    }
    .Materialsclosable{
        text-align: center;
    }
</style>
