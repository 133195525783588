import request from '@/request';

// 获取试卷详情v2
export function GetExerciseDetail (data) {
    return request({
        url: '/user/paper/detail?paperId='+data.paperId+'&belongId='+data.belongId+'&belongType='+data.belongType,
        method: 'get',
    });
}

// 获取作答结果
export function GetPaperResult (data) {
    return request({
        url: '/course/exercise/paper/result?userPaperId='+data,
        method: 'get',
    });
}

// 错题反馈
export function QuestionFeedback (data) {
    return request({
        url: '/question/feedback',
        method: 'post',
        data
    });
}

// 单题-作答提交
export function QuestionAnswer (data) {
    return request({
        url: '/course/exercise/question/answer',
        method: 'post',
        data
    });
}

// 提交作答-试卷
export function ExercisePaperAnswer (data) {
    return request({
        url: '/course/exercise/paper/answer',
        method: 'post',
        data
    });
}

// 模考开始提交数据
export function PaperExamStart (data) {
    return request({
        url: '/user/paper/exam/start',
        method: 'post',
        data
    });
}

// 点击查看模考点评
export function CorrectSee (data) {
    return request({
        url: '/course/paper/correct/see',
        method: 'post',
        data
    });
}